<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.85092 7.42546C4.85092 6.75569 4.30797 6.21273 3.63819 6.21273H1.21273C0.542958 6.21273 0 6.75569 0 7.42546V15.9146C0 16.5844 0.542958 17.1273 1.21273 17.1273H3.63819C4.30797 17.1273 4.85092 16.5844 4.85092 15.9146V7.42546Z"
    />
    <path
      d="M24 7.42546C24 6.75569 23.457 6.21273 22.7873 6.21273H20.3618C19.692 6.21273 19.1491 6.75569 19.1491 7.42546V15.9146C19.1491 16.5844 19.692 17.1273 20.3618 17.1273H22.7873C23.457 17.1273 24 16.5844 24 15.9146V7.42546Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.80903 6.21273C5.80903 5.54301 6.35204 5 7.02176 5H16.9765C17.6462 5 18.1892 5.54301 18.1892 6.21273V17.2928C18.1892 17.9625 17.6462 18.5056 16.9765 18.5056H15.4456L12.1646 13.9344C12.0693 13.785 11.8514 13.785 11.756 13.9344L8.47505 18.5056H7.02176C6.35204 18.5056 5.80903 17.9625 5.80903 17.2928V6.21273Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3076 19.7711C14.3123 19.7205 14.3011 19.6673 14.2697 19.6186L12.1646 16.3222C12.0693 16.1729 11.8513 16.1729 11.756 16.3222L9.65031 19.6186C9.54728 19.7801 9.66334 19.9917 9.8546 19.9917H14.0654C14.1992 19.9917 14.2964 19.8883 14.3076 19.7711Z"
    />
  </svg>
</template>
